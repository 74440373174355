/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .archived-claims-container {
        position: fixed;
        top: 15%;
        bottom: 2%;
        width: 200%;
        box-sizing: border-box;
        padding-left: 12px;
        padding-right: 12px;
        margin-left: 16px;
        margin-right: 16px;
        background-color: white;
        border-radius: 24px;
        overflow: auto;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
            
    .archived-claims-container {
        position: fixed;
        top: 15%;
        width: 120%;
        bottom: 2%;
        box-sizing: border-box;
        padding-left: 12px;
        padding-right: 12px;
        margin-left: 16px;
        margin-right: 16px;
        background-color: white;
        border-radius: 24px;
        overflow: auto;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .archived-claims-container {
        position: fixed;
        top: 15%;
        width: 120%;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background-color: white;
        border-radius: 24px;
        overflow: auto;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .archived-claims-container {
        position: fixed;
        top: 15%;
        width: 97%;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background-color: white;
        border-radius: 24px;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .archived-claims-container {
        position: fixed;
        top: 15%;
        width: 83%;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background-color: white;
        border-radius: 24px;
    }
    
}

.h1-archived-claims {
    position: fixed;
    top: 15%;
    padding: 8px 8px;
    font-size: 1.5em;
    color: #555555;
}