/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  
  .status-container {
    position: fixed;
    display: inline;
    box-sizing: border-box;
    padding-left: 55%;
    padding-right: 5%;
    top: 15%;
    bottom: 70%;
    right: 50%;
    width: inherit;
    overflow: auto;
    align-items: center;
  }

  .variants {
    border-radius: 8px;
    background-color: #018391;
    display: block;
    background-image: url("../img/variants-icon.png");
    background-repeat: no-repeat;
    background-size: 20% auto;
    background-position: 10% 50%;
    height: auto;
    width: 100%;
  }

  .claims-pending {
    border-radius: 8px;
    background-color: #8BC741;
    display: block;
    background-image: url("../img/claims-icon.png");
    background-repeat: no-repeat;
    background-size: 20% auto;
    background-position: 10% 50%;
    height: auto;
    width: 100%;
  }

  .weekly {
    border-radius: 8px;
    background-color: #42C7D0;
    display: block;
    background-image: url("../img/weekly-variant-value-icon.png");
    background-repeat: no-repeat;
    background-size: 20% auto;
    background-position: 10% 50%;
    height: auto;
    width: 100%;
  }

  .claims-closed {
    border-radius: 8px;
    background-color: #F4C831;
    display: block;
    background-image: url("../img/claims-pending-icon.png");
    background-repeat: no-repeat;
    background-size: 20% auto;
    background-position: 10% 50%;
    height: auto;
    width: 100%;
  }

  .adjustment-and-search-container {
    display: grid;
    position: fixed;
    top: 15%;
    bottom: 70%;
    padding-left: 5%;
    padding-right: 5%;
    width: inherit;
    left: 50%;
    box-sizing: border-box;
    float: right;
    align-items: center;
  }

  .adjustment-button {
    font-size: 0.55em;
    border: none;
    background-color: #41B7B6;
    text-align: center;
    color: white;
    height: 22px;
    width: 40%;
  }
  
  #search-field {
    background-image: url("../img/field-search-icon.png");
    background-repeat: no-repeat;
    background-position: 4% 50%;
    background-color: white;
    height: 22px;
    width: 40%;
    border-radius: 4px;
    border: none;
    text-align: right;
  }

  .h1-active-claims {
    position: fixed;
    font-size: 0.90em;
    padding-bottom: 8px;
  }

  .active-claims-container {
    position: fixed;
    background-color: white;
    border-radius: 24px;
    margin-left: 16px;
    margin-right: 16px;
    top: 40%;
    bottom: 10%;
    width: 89%;
    overflow-x: auto;
    padding-left: 12px;
    padding-right: 12px;
  }

  .table-active-claims {
    position: sticky;
    border-collapse: separate;
    border-spacing: 0 24px;
    width: 1200px;
    table-layout: fixed;
    font-size: 0.55em;
    margin-top: 16px;
  }

  .alter-button {
    width: 62px;
  }

  .close-button {
    width: 62px;
  }

  .archive-button {
    width: 62px;
  }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

  .status-container {
    position: fixed;
    box-sizing: border-box;
    top: 15%;
    bottom: 70%;
    width: inherit;
    padding-left: 25%;
    padding-right: 25%;
    display: inline;
    overflow-y: auto;
    align-items: center;
  }
  
  .variants {
    border-radius: 8px;
    background-color: #018391;
    display: block;
    background-image: url("../img/variants-icon.png");
    background-repeat: no-repeat;
    background-size: 20% auto;
    background-position: 10% 50%;
    height: auto;
    width: 100%;
  }

  .claims-pending {
    border-radius: 8px;
    margin-left: 6px;
    margin-right: 6px;
    background-color: #8BC741;
    display: block;
    background-image: url("../img/claims-icon.png");
    background-repeat: no-repeat;
    background-size: 20% auto;
    background-position: 10% 50%;
    height: auto;
    width: 100%;
  }

  .weekly {
    border-radius: 8px;
    margin-left: 6px;
    margin-right: 6px;
    background-color: #42C7D0;
    display: block;
    background-image: url("../img/weekly-variant-value-icon.png");
    background-repeat: no-repeat;
    background-size: 20% auto;
    background-position: 10% 50%;
    height: auto;
    width: 100%;
  }

  .claims-closed {
    border-radius: 8px;
    margin-left: 6px;
    background-color: #F4C831;
    display: block;
    background-image: url("../img/claims-pending-icon.png");
    background-repeat: no-repeat;
    background-size: 20% auto;
    background-position: 10% 50%;
    height: auto;
    width: 100%;
  }

  .adjustment-and-search-container {
    display: block;
    position: fixed;
    top: 30%;
    width: inherit;
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 24px;
    box-sizing: border-box;
    float: right;
  }

  .adjustment-button {
    font-size: 0.55em;
    border: none;
    background-color: #41B7B6;
    text-align: center;
    color: white;
    height: 20px;
    width: 30%;
  }
  
  #search-field {
    background-image: url("../img/field-search-icon.png");
    background-repeat: no-repeat;
    background-position: 4% 50%;
    background-color: white;
    float: right;
    height: 20px;
    width: 30%;
    border-radius: 4px;
    border: none;
    text-align: right;
  }

  .h1-active-claims {
    position: fixed;
    font-size: 1.10em;
    padding-bottom: 8px;
  }

  .active-claims-container {
    position: fixed;
    background-color: white;
    border-radius: 24px;
    margin-left: 16px;
    margin-right: 16px;
    top: 40%;
    bottom: 10%;
    width: 92%;
    overflow-x: auto;
    padding-left: 12px;
    padding-right: 12px;
  }

  .table-active-claims {
    position: sticky;
    font-size: 0.55em;
    border-collapse: separate;
    border-spacing: 0 24px;
    width: 800px;
    table-layout: fixed;
    margin-top: 16px;
  }

  .alter-button {
    width: 68px;
  }

  .close-button {
    width: 68px;
  }

  .archive-button {
    width: 68px;
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  
  .status-container {
    position: fixed;
    box-sizing: border-box;
    top: 15%;
    bottom: 70%;
    width: inherit;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
  }

  .variants {
    border-radius: 8px;
    background-color: #018391;
    display: block;
    background-image: url("../img/variants-icon.png");
    background-repeat: no-repeat;
    background-size: 25% auto;
    background-position: 10% 50%;
    height: auto;
    padding: 4px 4px;
    width: 20%;
  }

  .claims-pending {
    border-radius: 8px;
    margin-left: 6px;
    margin-right: 6px;
    background-color: #8BC741;
    display: block;
    background-image: url("../img/claims-icon.png");
    background-repeat: no-repeat;
    background-size: 25% auto;
    background-position: 10% 50%;
    height: auto;
    padding: 4px 4px;
    width: 20%;
  }

  .weekly {
    border-radius: 8px;
    margin-left: 6px;
    margin-right: 6px;
    background-color: #42C7D0;
    display: block;
    background-image: url("../img/weekly-variant-value-icon.png");
    background-repeat: no-repeat;
    background-size: 25% auto;
    background-position: 10% 50%;
    height: auto;
    padding: 4px 4px;
    width: 20%;
  }

  .claims-closed {
    border-radius: 8px;
    margin-left: 6px;
    background-color: #F4C831;
    display: block;
    background-image: url("../img/claims-pending-icon.png");
    background-repeat: no-repeat;
    background-size: 25% auto;
    background-position: 10% 50%;
    height: auto;
    padding: 4px 4px;
    width: 20%;
  }

  .adjustment-and-search-container {
    display: inline;
    position: fixed;
    top: 30%;
    width: inherit;
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 24px;
    box-sizing: border-box;
  }

  .adjustment-button {
    font-size: 0.60em;
    border: none;
    background-color: #41B7B6;
    text-align: center;
    color: white;
    height: 20px;
    width: 18%;
  }
  
  #search-field {
    background-image: url("../img/field-search-icon.png");
    background-repeat: no-repeat;
    background-position: 4% 50%;
    background-color: white;
    float: right;
    height: 20px;
    width: 18%;
    border-radius: 4px;
    border: none;
    text-align: right;
  }

  .h1-active-claims {
    position: fixed;
    font-size: 1.25em;
    padding-bottom: 8px;
  }

  .table-active-claims {
    font-size: 0.67em;
    border-collapse: separate;
    border-spacing: 0 24px;
    width: 100%;
    table-layout: fixed;
    margin-top: 16px;
  }

  .active-claims-container {
    position: fixed;
    background-color: white;
    border-radius: 24px;
    margin-left: 16px;
    margin-right: 16px;
    top: 40%;
    bottom: 10%;
    width: 93%;
    padding-left: 12px;
    padding-right: 12px;
  }

  .alter-button {
    width: 68px;
  }

  .close-button {
    width: 68px;
  }

  .archive-button {
    width: 68px;
  }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

  .status-container {
    position: fixed;
    box-sizing: border-box;
    top: 15%;
    bottom: 70%;
    width: inherit;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
  }
  
  .variants {
    border-radius: 8px;
    background-color: #018391;
    display: block;
    background-image: url("../img/variants-icon.png");
    background-repeat: no-repeat;
    background-size: 25% auto;
    background-position: 10% 50%;
    height: auto;
    padding: 4px 4px;
    width: 20%;
  }

  .claims-pending {
    border-radius: 8px;
    margin-left: 6px;
    margin-right: 6px;
    background-color: #8BC741;
    display: block;
    background-image: url("../img/claims-icon.png");
    background-repeat: no-repeat;
    background-size: 25% auto;
    background-position: 10% 50%;
    height: auto;
    padding: 4px 4px;
    width: 20%;
  }

  .weekly {
    border-radius: 8px;
    margin-left: 6px;
    margin-right: 6px;
    background-color: #42C7D0;
    display: block;
    background-image: url("../img/weekly-variant-value-icon.png");
    background-repeat: no-repeat;
    background-size: 25% auto;
    background-position: 10% 50%;
    height: auto;
    padding: 4px 4px;
    width: 20%;
  }

  .claims-closed {
    border-radius: 8px;
    margin-left: 6px;
    background-color: #F4C831;
    display: block;
    background-image: url("../img/claims-pending-icon.png");
    background-repeat: no-repeat;
    background-size: 25% auto;
    background-position: 10% 50%;
    height: auto;
    padding: 4px 4px;
    width: 20%;
  }

  .adjustment-and-search-container {
    display: inline;
    position: fixed;
    top: 30%;
    width: inherit;
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 24px;
    box-sizing: border-box;
  }

  .adjustment-button {
    font-size: 0.75em;
    border: none;
    background-color: #41B7B6;
    text-align: center;
    color: white;
    height: 20px;
    width: 18%;
  }
  
  #search-field {
    background-image: url("../img/field-search-icon.png");
    background-repeat: no-repeat;
    background-position: 4% 50%;
    background-color: white;
    float: right;
    height: 20px;
    width: 18%;
    border-radius: 4px;
    border: none;
    text-align: right;
  }

  .h1-active-claims {
    position: fixed;
    font-size: 1.5em;
    padding-bottom: 8px;
  }

  .active-claims-container {
    position: fixed;
    background-color: white;
    border-radius: 24px;
    margin-left: 16px;
    margin-right: 16px;
    top: 40%;
    bottom: 10%;
    width: 95%;
    padding-left: 12px;
    padding-right: 12px;
  }

  .table-active-claims {
    font-size: 0.90em;
    border-collapse: separate;
    border-spacing: 0 24px;
    width: 100%;
    table-layout: fixed;
    margin-top: 20px;
  }

  .alter-button {
    width: 74px;
  }

  .close-button {
    width: 74px;
  }

  .archive-button {
    width: 74px;
  }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

  .status-container {
    position: fixed;
    box-sizing: border-box;
    top: 15%;
    bottom: 70%;
    width: inherit;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
  }

  .variants {
    border-radius: 8px;
    background-color: #018391;
    display: block;
    background-image: url("../img/variants-icon.png");
    background-repeat: no-repeat;
    background-size: 25% auto;
    background-position: 10% 50%;
    height: auto;
    padding: 4px 4px;
    width: 20%;
  }

  .claims-pending {
    border-radius: 8px;
    margin-left: 6px;
    margin-right: 6px;
    background-color: #8BC741;
    display: block;
    background-image: url("../img/claims-icon.png");
    background-repeat: no-repeat;
    background-size: 25% auto;
    background-position: 10% 50%;
    height: auto;
    padding: 4px 4px;
    width: 20%;
  }

  .weekly {
    border-radius: 8px;
    margin-left: 6px;
    margin-right: 6px;
    background-color: #42C7D0;
    display: block;
    background-image: url("../img/weekly-variant-value-icon.png");
    background-repeat: no-repeat;
    background-size: 25% auto;
    background-position: 10% 50%;
    height: auto;
    padding: 4px 4px;
    width: 20%;
  }

  .claims-closed {
    border-radius: 8px;
    margin-left: 6px;
    background-color: #F4C831;
    display: block;
    background-image: url("../img/claims-pending-icon.png");
    background-repeat: no-repeat;
    background-size: 25% auto;
    background-position: 10% 50%;
    height: auto;
    padding: 4px 4px;
    width: 20%;
  }

  .adjustment-and-search-container {
    display: inline;
    position: fixed;
    top: 30%;
    width: inherit;
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 24px;
    box-sizing: border-box;
  }

  .adjustment-button {
    border: none;
    background-color: #41B7B6;
    text-align: center;
    color: white;
    font-size: 16px;
    height: auto;
    width: auto;
    box-sizing: border-box;
    padding: 4px 16px;
  }
  
  #search-field {
    background-image: url("../img/field-search-icon.png");
    background-repeat: no-repeat;
    background-position: 4% 50%;
    background-color: white;
    float: right;
    height: 20px;
    width: 18%;
    border-radius: 4px;
    border: none;
    text-align: right;
  }

  .h1-active-claims {
    position: fixed;
    font-size: 1.5em;
    padding-bottom: 8px;
  }

  .active-claims-container {
    position: fixed;
      top: 40%;
      width: 83%;
      bottom: 10%;
      box-sizing: border-box;
      margin-left: 16px;
      margin-right: 16px;
      padding-left: 12px;
      padding-right: 12px;
      background-color: white;
      border-radius: 24px;
  }

  .table-active-claims {
    font-size: 0.90em;
    border-collapse: separate;
    border-spacing: 0 24px;
    width: 100%;
    table-layout: fixed;
    margin-top: 20px;
  }

  .alter-button {
    width: 80px;
  }

  .close-button {
    width: 80px;
  }

  .archive-button {
    width: 80px;
  }

}

.h1-active-claims {
  color: #555555;
  padding: 8px 8px;
  font-size: 1.5em;
}

.variants h1, .claims-pending h1, .weekly h1, .claims-closed h1{
  color: white;
  font-size: 110%;
  margin-left: 40%;
  margin-top: 16px;
}

.variants p, .claims-pending p, .weekly p, .claims-closed p {
  color: white;
  font-size: 70%;
  margin-left: 40%;
}

.variants strong, .claims-pending strong, .weekly strong, .claims-closed strong {
  color: white;
  font-size: 140%;
  margin-left: 40%;
}


tr:nth-child(1) {
  padding-top: 8px;
}

.open-status {
  font-weight: bold;
  color: #3F9E90;
}

.closed-status {
  font-weight: bold;
  color: #8F8F91;
}

.pending-status {
  font-weight: bold;
  color: #CE8B3F;
}

.table-header {
  font-size: 1.25em;
  font-style: italic;
  font-weight: 500;
  color: #00B3B5;
  text-align: left;
  border-bottom: solid thin #00B3B5; 
  white-space: nowrap;
}

.table-data {
  font-size: 1.20em;
  border: 0;
  text-align: left;
  white-space: nowrap;
}

tr {
  width: 16%;
}

.alter-button {
  border: none;
  color: white;
  background-color: #3F9E90;
  height: 16px;
  cursor: pointer;
  text-align: center;
}

.close-button {
  border: none;
  color: white;
  background-color: #5E6061;
  height: 16px;
  cursor: pointer;
  text-align: center;
}

.archive-button {
  content: 'Archive';
  border: none;
  color: white;
  background-color: #CAC7C8;
  height: 16px;
  cursor: pointer;
  text-align: center;
}

.change-page {  
  display: flex;  
  position: fixed;
  top: 92%;
  width: inherit;
  justify-content: center;
}

.button-page {
  margin-left: 2px;
  margin-right: 2px;
  color: #959595;
  border: none;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  font-size: 1.0em;
  font-style: italic;
  width: 24px;
}

.usercontainer {
  position: fixed;
  display: inline;
  box-sizing: border-box;
  overflow: auto;
  align-items: center;
  width: 80%
}

.usercard{
  width: 50%;
}