/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    
    .reports-container {
        position: fixed;
        top: 15%;
        width: 94%;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background: white;
        border-radius: 24px;
        overflow: auto;
    }

    .header-content-container {        
        width: 98%;
        position: fixed;
        box-sizing: border-box;
        padding-right: 24px;
    }

    .table-claim-report-1 {
        position: sticky;
        margin-top: 48px;
        border-top: solid thin #00B3B5;
        border-bottom: solid thin #00B3B5;
        border-collapse: separate;
        border-spacing: 0 6px;
        width: 1400px;
    }

    .table-claim-report-2 {
        box-sizing: border-box;
        padding-right: 12px;
        margin-top: 16px;
        border-spacing: 0 12px;
        width: 1500px;
    }

    .summary {
        position: sticky;
        box-sizing: border-box;
        padding-right: 12px;
        width: 1500px;
        border-spacing: 0 12px;
        padding-top: 8px;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
        
    .reports-container {
        position: fixed;
        top: 15%;
        width: 96%;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background: white;
        border-radius: 24px;
        overflow: auto;
    }

    .header-content-container {        
        width: 98%;
        position: fixed;
        box-sizing: border-box;
        padding-right: 24px;
    }

    .table-claim-report-1 {
        position: sticky;
        margin-top: 48px;
        border-top: solid thin #00B3B5;
        border-bottom: solid thin #00B3B5;
        border-collapse: separate;
        border-spacing: 0 6px;
        width: 1400px;
    }

    .table-claim-report-2 {
        margin-top: 16px;
        border-spacing: 0 12px;
        width: 1500px;
    }

    .summary {
        width: 1500px;
        border-spacing: 0 12px;
        padding-top: 8px;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
   
    .reports-container {
        position: fixed;
        top: 15%;
        width: 95%;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background: white;
        border-radius: 24px;
        overflow: auto;
    }

    .header-content-container {        
        width: 98%;
        position: fixed;
        box-sizing: border-box;
        padding-right: 24px;
    }

    .table-claim-report-1 {
        position: sticky;
        margin-top: 48px;
        border-top: solid thin #00B3B5;
        border-bottom: solid thin #00B3B5;
        border-collapse: separate;
        border-spacing: 0 6px;
        width: 1400px;
    }

    .table-claim-report-2 {
        margin-top: 16px;
        border-spacing: 0 12px;
        width: 1500px;
    }

    .summary {
        width: 1500px;
        border-spacing: 0 12px;
        padding-top: 8px;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    
    .reports-container {
        position: fixed;
        top: 15%;
        width: 97%;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background: white;
        border-radius: 24px;
    }

    .header-content-container {        
        width: 98%;
        position: fixed;
        box-sizing: border-box;
        padding-right: 24px;
    }

    .table-claim-report-1 {
        position: sticky;
        margin-top: 48px;
        border-top: solid thin #00B3B5;
        border-bottom: solid thin #00B3B5;
        border-collapse: separate;
        border-spacing: 0 6px;
        width: 98%;
    }

    .table-claim-report-2 {
        margin-top: 16px;
        border-spacing: 0 12px;
        width: 100%;
    }

    .summary {
        width: 100%;
        border-spacing: 0 12px;
        padding-top: 8px;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
    .reports-container {
        position: fixed;
        top: 15%;
        width: 83%;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background-color: white;
        border-radius: 24px;
    }

    .header-content-container {        
        width: 81%;
        position: fixed;
        box-sizing: border-box;
        padding-right: 24px;
    }

    .table-claim-report-1 {
        position: sticky;
        margin-top: 48px;
        border-top: solid thin #00B3B5;
        border-bottom: solid thin #00B3B5;
        border-collapse: separate;
        border-spacing: 0 6px;
        width: 98%;
    }

    .table-claim-report-2 {
        margin-top: 16px;
        border-spacing: 0 12px;
        width: 100%;
    }

    .summary {
        width: 100%;
        border-spacing: 0 12px;
        padding-top: 8px;
    }

}

.h1-reports {
    position: fixed;
    top: 15%;
    padding: 8px 8px;
    font-size: 1.5em;
    color: #555555;
}

.button-archive {
    font-size: 0.67em;
    text-align: center;
    color: white;
    background-color: #CCCCCC;
    border: none;
    width: 100px;
    height: 20px;
    float: right;
    margin: 16px 12px 0 0;
}

.table-claim-report-1 th {
    border: none;
    font-style: italic;
    font-weight: 500;
    color: #00B3B5;
    text-align: left;
    font-size: 1.20em;   
}

.table-claim-report-1 td {
    color: black;
    text-align: left;
    font-size: 1.15em;
}

.table-claim-report-2 th {
    font-style: italic;
    font-weight: 500;
    color: #00B3B5;
    font-size: 1.20em;
    border-bottom: solid thin #00B3B5;
}

.table-claim-report-2 th:first-child, .table-claim-report-2 th:nth-child(2), .table-claim-report-2 th:nth-child(3), .table-claim-report-2 th:nth-child(4) {
    text-align: left;
} 

.table-claim-report-2 th:last-child {
    text-align: right;
    padding-right: 5%;
}

.table-claim-report-2 td {
    color: black;
    font-size: 1.15em;
}

.table-claim-report-2 td:first-child, .table-claim-report-2 td:nth-child(2), .table-claim-report-2 td:nth-child(3), .table-claim-report-2 td:nth-child(4) {
    text-align: left;
} 

.table-claim-report-2 td:last-child {
    text-align: right;
    padding-right: 5%;
}

.table-claim-report-2 {
    border-bottom: solid thin #00B3B5;
}

.button-export-report {
    border: none;
    background-color: #00B3B5;
    color: white;
    font-size: 0.67em;
    text-align: center;
    font-weight: bold;
    float: left;
    height: 20px;
    width: 100px;
}

.strong-variant {
    float: right;
    padding-right: 5%;
}

.strong-variant-value {
    float: right;
    padding-right: 5%;
    color: red;
}