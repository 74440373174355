/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

.custom-file-input {
    padding: 0;
    width: 100%;
    height: 80%;
    background-image: url("../img/upload-file.png");
    background-size: 20% auto;
    background-color: #CCCCCC;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border: none;
}

.custom-file-input:hover {
    opacity: 50%;
}

.upload-status {
    float: left;
    font-weight: bold;
}