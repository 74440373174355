/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .insurance-companies-container {
        position: fixed;
        top: 15%;
        bottom: 2%;
        width: 94%;
        box-sizing: border-box;
        padding-left: 12px;
        padding-right: 12px;
        margin-left: 16px;
        margin-right: 16px;
        background-color: white;
        border-radius: 24px;
        overflow-x: scroll;
    }

    table {
        position: absolute;
        border-spacing: 0px 8px;
        border-collapse: separate;
        box-sizing: border-box;
        padding-right: 240px;
        table-layout: fixed;
        width: 150%;
        overflow-x: scroll;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
            
    .insurance-companies-container {
        position: fixed;
        top: 15%;
        width: 96%;
        bottom: 2%;
        box-sizing: border-box;
        padding-left: 12px;
        padding-right: 12px;
        margin-left: 16px;
        margin-right: 16px;
        background-color: white;
        border-radius: 24px;
        overflow-x: scroll;
    }

    table {
        position: absolute;
        border-spacing: 0px 8px;
        border-collapse: separate;
        box-sizing: border-box;
        padding-right: 72px;
        width: 1400px;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
   
    .insurance-companies-container {
        position: fixed;
        top: 15%;
        width: 97%;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background-color: white;
        border-radius: 24px;
        overflow-x: auto;
    }

    table {
        position: absolute;
        border-spacing: 0px 8px;
        border-collapse: separate;
        box-sizing: border-box;
        padding-right: 36px;
        width: 2000px;
        
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .insurance-companies-container {
        position: fixed;
        top: 15%;
        width: 97%;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background-color: white;
        border-radius: 24px;
        overflow-x: auto;
    }

    table {
        position: sticky;
        border-spacing: 0px 8px;
        border-collapse: separate;
        box-sizing: border-box;
        table-layout: auto;
        overflow-x: auto;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
    .insurance-companies-container {
        position: fixed;
        top: 15%;
        width: 83%;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background-color: white;
        border-radius: 24px;
        overflow-x: scroll;
    }

    table {
        position: sticky;
        border-spacing: 0px 8px;
        border-collapse: separate;
        box-sizing: border-box;
        table-layout: auto;
        overflow-x: scroll;
    }
}

.h1-insurance-companies {
    position: fixed;
    top: 15%;
    padding: 8px 8px;
    font-size: 1.5em;
    color: #555555;
}

thead {
    text-align: left;
    color: #00B3B5;
    font-size: 1.0em;
    font-weight: 300;
}

th {
    border-bottom: 1px solid #00B3B5;
    padding: 4px 0;
    line-height: 2;
}

strong {
    padding-left: 0px;
    padding-right: 0px;
}

td {
    color: #555555;
    padding-left: 0px;
    padding-right: 0px;
}

.styled-data {
    color: #00B3B5;
}

tbody {
    color: gray;
    text-align: left;
    font-size: 1.0em;
    font-weight: normal;
}