/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .comparison-results-container {
        position: fixed;
        top: 15%;
        bottom: 2%;
        width: 95%;
        box-sizing: border-box;
        padding-left: 12px;
        padding-right: 12px;
        margin-left: 16px;
        margin-right: 16px;
        background-color: white;
        border-radius: 24px;
        overflow-x: scroll;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
            
    .comparison-results-container {
        position: fixed;
        top: 15%;
        width: 96%;
        bottom: 2%;
        box-sizing: border-box;
        padding-left: 12px;
        padding-right: 12px;
        margin-left: 16px;
        margin-right: 16px;
        background-color: white;
        border-radius: 24px;
        overflow-x: scroll;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
   
    .comparison-results-container {
        position: fixed;
        top: 15%;
        width: 97%;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background-color: white;
        border-radius: 24px;
        overflow-x: auto;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .comparison-results-container {
        position: fixed;
        top: 15%;
        width: 97%;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background-color: white;
        border-radius: 24px;
        overflow-x: auto;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
    .comparison-results-container {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        top: 15%;
        width: 83%;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding: 16px 12px;
        background-color: white;
        border-radius: 24px;
    }

}

.h1-comparison-results {
    position: fixed;
    left: 17%;
    top: 15%;
    padding: 16px 16px;
    font-size: 1.5em;
    color: #555555;
}

.zone-name {
    color: #00B3B5;
    font-weight: bold;
    border-bottom: 1px solid #CCCCCC;
    display: flex;
    width: auto;
}

.table-zones {
    width: auto;
}

.table-zones span {
    color: #555555;
}

.table-zones tr {
    overflow: auto;
}

.filter-container {
    display: inline;
    background-color: white;
    position: fixed;
    top: 15%;
    width: 81%;
    align-items: center;
    z-index: 10;
    border-radius: 8px;
    padding: 12px 0px;
}

#filter-percentage {
    background-color: #723e49;
    float: right;
    padding: 4px 0px;
}

.filter-label {
    font-weight: bold;
    font-style: italic;
    color: #00B3B5;
    float: right;
    padding: 0px 8px;
}

.match-percentage-data {
    font-weight: bold;
    font-style: italic;
    color: #00B3B5;
}

.match-percentage {
    font-weight: bold;
    font-style: italic;
    color: #00B3B5;
}

.match-percentage strong {
    font-weight: bold;
    font-style: italic;
    color: #723e49;
}

.match-percentage-data-strong {
    font-weight: bold;
    font-style: italic;
    color: #723e49;
}

.pairings-container {
    display: inline-flex;
    position: relative;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 12px;
    padding: 16px 16px;
    overflow-y: auto;
    position: fixed;
    top: 22%;
    width: 80%;
    height: 74%;
    border-radius: 24px;
}

.line-item-table {
    position: relative;
    margin: 12px 6px;
    box-sizing: border-box;
    padding: 24px 6px;
    height: 100px;
    width: auto;
    background-color: #DDDDDD;
    border-collapse: collapse;
    white-space: nowrap;
    overflow-x: auto;
}


.line-item-table tr:first-child() td:first-child(){
    overflow-x: auto;
}

.line-item-table tr:nth-child(2) td:first-child{
    overflow-x: auto;
}

.line-item-table tr:last-child() td{
    overflow-x: hidden;
}

.on-load-message-container {
    padding: 12px 6px;
    position: fixed;
    display: flex;
    justify-content: center;
    top: 30%;
    align-items: center;
    width: inherit;
}

.loading-comparisons-text {
    font-weight: bold;
    color: #1976D2;
    font-size: 1.5em;
}

.toggle-results-button {
    border: none;
    background-color: #00B3B5;
    color: white;
    font-size: 0.67em;
    text-align: center;
    font-weight: bold;
    float: left;
    height: 28px;
    width: 100px;
    margin: 0px 0px 0px 8px;
}

.add-dropdown-button {
    background-color: #00B3B5;
    margin: 4px 0px;
    border: none;
    color: white;
    padding: 0px 8px;
    text-align: center;
    text-decoration: none;
    display: block;
    font-size: 1.5em;
    border-radius: 30%;
}

.dropdown-style {
    text-align: center;
    margin: 8px 12px;
    font-weight: bold;
    font-size: 1em;
    padding: 1px 0px 0px 0px;
    color: #555555;
    border: 1px dotted #555555;
    border-radius: 4px;
    background-color: #DDDDDD;
    width: 50px
}

.comparison-results-actions-div {
    width: 80%;
    padding: 8px 8px;
    position: fixed; 
    display: flex;
    align-items: center; 
    justify-content: flex-end; 
    top: 15%;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000; /* High z-index to appear above other elements */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  z-index: 1001; /* Ensure content appears above overlay */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

#pdfCopyPreview {
  padding: 10px;
}

/*
table:first-child() {
    height: 40%;
}

table:nth-child(1) {
    height: 40%;
}

table:last-child() {
    height: 40%;
}
*/