/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .adjustments-container {
        position: fixed;
        bottom: 7%;
        width: 90%;
        box-sizing: border-box;
        border-top: solid thin #00B3B5;
        border-bottom: solid thin #00B3B5;
        padding-right: 12px;
        padding-left: 12px;
    }
    
    .h2-adjustments {
        color: #555555;
        position: fixed;
        font-size: 1.0em;
        padding-top: 8px;
        padding-bottom: 8px;
    }
    
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

    .adjustments-container {
        position: fixed;
        bottom: 7%;
        width: 90%;
        box-sizing: border-box;
        border-top: solid thin #00B3B5;
        border-bottom: solid thin #00B3B5;
        padding-right: 12px;
        padding-left: 12px;
    }
    
    .h2-adjustments {
        color: #555555;
        position: fixed;
        font-size: 1.0em;
        padding-top: 8px;
        padding-bottom: 8px;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .adjustments-container {
        position: fixed;
        bottom: 7%;
        width: 90%;
        box-sizing: border-box;
        border-top: solid thin #00B3B5;
        border-bottom: solid thin #00B3B5;
        padding-right: 12px;
        padding-left: 12px;
    }
    
    .h2-adjustments {
        color: #555555;
        position: fixed;
        font-size: 1.0em;
        padding-top: 8px;
        padding-bottom: 8px;
    }
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .adjustments-container {
        position: fixed;
        bottom: 7%;
        width: 92%;
        box-sizing: border-box;
        border-top: solid thin #00B3B5;
        border-bottom: solid thin #00B3B5;
        padding-right: 12px;
        padding-left: 12px;
    }
    
    .h2-adjustments {
        color: #555555;
        position: fixed;
        font-size: 1.0em;
        padding-top: 8px;
        padding-bottom: 8px;
    }
    
    
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
    .adjustments-container {
        position: fixed;
        top: 56%;
        bottom: 7%;
        width: 79%;
        box-sizing: border-box;
        border-top: solid thin #00B3B5;
        border-bottom: solid thin #00B3B5;
        padding-right: 12px;
        padding-left: 12px;
    }
    
    .h2-adjustments {
        color: #555555;
        position: fixed;
        font-size: 1.0em;
        padding-top: 8px;
        padding-bottom: 8px;
    }

}

.grid-container-1 {
    display: grid;
    grid-template-columns: 100% ;
    grid-template-rows: auto;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-top: 40px;
    padding-left: 12px;
    padding-right: 12px;
}

.grid-container-2 {
    display: grid;
    grid-template-columns: 30% 40% 30%;
    align-items: center;
    grid-template-rows: auto;
    box-sizing: border-box;
    width: 100%;
    height: 30%;
    padding-top: 48px;
    padding-left: 12px;
    padding-right: 12px;
}

.grid-container-3 {
    display: grid;
    grid-template-columns: 30% 40% 30%;
    grid-template-rows: auto;
    box-sizing: border-box;
    width: 100%;
    height: 10%;
    padding-left: 12px;
    padding-right: 12px;
}

.internal-text {
    text-align: right;
    float: right;
    font-size: .67em;
}

.img-resize {
    padding-top: 12px;
    width: 10%;
    height: auto;
}

.update-file-style {
    color: #00B3B5;
    font-style: italic;
}

.percentage-container {
    display: flex;
    justify-self: center;
    align-items: center;
    justify-content: start;
    align-items: center;
    width: 70%;
    background-color: #CCCCCC;
    height: 10px;
    border: none;
}

.progress-bar {
    height: 10px;
    border: none;
}

.percentage-readout {
    color: black;
    font-size: 1.0em;
}

.button-view-report {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Arial';
    font-size: 100%;
    font-weight: 300;
    text-align: center;
    color: white;
    background-color: #CCCCCC;
    border: none;
    width: 80%;
    height: 75%;
    justify-self: end;
    text-decoration: none;
}

.button-view-report-blue {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Arial';
    font-size: 100%;
    font-weight: 300;
    text-align: center;
    color: white;
    background-color: #00B3B5;
    border: none;
    width: 80%;
    height: 75%;
    justify-self: end;
    text-decoration: none;
}

.button-view-report:hover {
    opacity: 50%;
}

.button-view-report-blue:hover {
    opacity: 50%;

}
.button-run-variant-finder {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-family: 'Arial';
    font-size: 80%;
    padding: 0;
    border-radius: 0px;
    font-weight: 300;
    text-align: center;
    color: white;
    background-color: #CCCCCC;
    border: none;
    width: 80%;
    height: 75%;
    justify-self: start;
}

.button-run-variant-finder-blue {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-family: 'Arial';
    font-size: 80%;
    padding: 0;
    border-radius: 0px;
    font-weight: 300;
    text-align: center;
    color: white;
    background-color: #00B3B5;
    border: none;
    width: 80%;
    height: 75%;
    justify-self: start;
}

.button-run-variant-finder-black {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-family: 'Arial';
    font-size: 80%;
    padding: 0;
    border-radius: 0px;
    font-weight: 300;
    text-align: center;
    color: white;
    background-color: #333333;
    border: none;
    width: 80%;
    height: 75%;
    justify-self: start;
}

.button-see-all {
    color: black;
    font-size: 0.67em;
    font-weight: bold;
    font-style: italic;
    text-align: right;
    background: none;
    border: none;
    width: auto;
    height: 12px;
    justify-self: end;
}

.button-file-upload{
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #CCCCCC;
    width: 98%;
    height: 150px;
    text-align: center;
}

