/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .profile-container {
        position: fixed;
        top: 15%;
        width: inherit;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background: white;
        border-radius: 24px;
        overflow: auto;
        box-sizing: border-box;
    }
    
    .subscription-status {
        padding-top: 8px;
        position: sticky;
        top: 55%;
        bottom: 20%;
        width: 500px;
        margin-left: 16px;
        margin-right: 16px;
        display: flex;
        justify-content: space-evenly;
        border-top: solid thin #00B3B5;
        margin-top: 24px;
    }        
    
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

    .profile-container {
        position: fixed;
        top: 15%;
        width: inherit;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background: white;
        border-radius: 24px;
        box-sizing: border-box;
    }
    
    .subscription-status {
        padding-top: 8px;
        position: fixed;
        top: 60%;
        bottom: 20%;
        width: inherit;
        margin-left: 16px;
        margin-right: 16px;
        display: flex;
        justify-content: space-evenly;
        border-top: solid thin #00B3B5;
        margin-top: 24px;
    } 

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .profile-container {
        position: fixed;
        top: 15%;
        width: inherit;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background: white;
        border-radius: 24px;
        box-sizing: border-box;
    }
    
    .subscription-status {
        padding-top: 8px;
        position: fixed;
        top: 60%;
        bottom: 20%;
        width: inherit;
        margin-left: 16px;
        margin-right: 16px;
        display: flex;
        justify-content: space-evenly;
        border-top: solid thin #00B3B5;
        margin-top: 24px;
    }  

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .profile-container {
        position: fixed;
        top: 15%;
        width: inherit;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background: white;
        border-radius: 24px;
        box-sizing: border-box;
    }
    
    .subscription-status {
        padding-top: 8px;
        position: fixed;
        top: 60%;
        bottom: 20%;
        width: inherit;
        margin-left: 16px;
        margin-right: 16px;
        display: flex;
        justify-content: space-evenly;
        border-top: solid thin #00B3B5;
        margin-top: 24px;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .profile-container {
        position: fixed;
        top: 15%;
        width: 83%;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background: white;
        border-radius: 24px;
        box-sizing: border-box;
    }

    
    .subscription-status {
        padding-top: 8px;
        position: fixed;
        top: 60%;
        bottom: 20%;
        width: 80%;
        margin-left: 16px;
        margin-right: 16px;
        display: flex;
        justify-content: space-evenly;
        border-top: solid thin #00B3B5;
        margin-top: 24px;
    }

}

.edit-button-container {
    position: fixed;
    top: 15%;
    right: 0%;
    padding: 6px 12px;
    margin-right: 16px;
    margin-top: 12px;
}

.picture-container {
    display: flex;
    position: fixed;
    right: 70%;
    left: 15%;
    top: 22%;
    justify-content: center;
}

.edit-button {
    font-size: 0.67em;
    text-align: center;
    color: white;
    background-color: #CCCCCC;
    border: none;
    cursor: not-allowed; /* at present */
    width: 100px;
    height: 20px;
    padding: 0;
    margin: 0 auto;
}
.activate-button {
    text-align: center;
    color: white;
    background-color: #00B3B5;
    border: none;
    cursor: not-allowed; /* at present */
    width: 100px;
    height: 20px;
    padding: 0;
    margin: 0 12px;
}
.deactivate-button {
    text-align: center;
    color: white;
    background-color: #555555;
    border: none;
    cursor: not-allowed; /* at present */
    width: 100px;
    height: 20px;
    padding: 0;
    margin: 0 12px;
}
.pricing-button {
    font-size: 24px;
    text-align: center;
    color: white;
    background-color: #00B3B5;
    border: none;
    width: fit-content;
    height: fit-content;
    padding: 12px;
    margin: 0 auto;
}
.profile-pic {
    width: auto;
    height: auto;
}

.h1-style {
    font-size: 1.45em;
    color: #555555;
}

.black-small {
    font-size: 1.0em;
    color: #555555;
}

.black-large {
    font-size: 2.0em;
    color: #555555;
}

.green-large {
    font-size: 2.0em;
    color: #00B3B5;
}

.black-smaller {
    font-size: 0.95em;
    color: #555555;
}

.black-bold {
    color: #555555;
    font-weight: bold;
}

.green-bold {
    color: #00B3B5;
    font-weight: bold;
}

.black-small-bold {
    color: #555555;
    font-size: 1.0em;
    font-weight: bold;
}

.green-smaller-bold {
    color: #00B3B5;
    font-size: 0.67em;
    font-weight: bold;
}

.green-large-bold {
    color: #00B3B5;
    font-weight: bold;
}

.green-small-bold {
    display: inline;
    font-size: 1.15em;
    font-weight: 1000;
    color: #00B3B5;
}
.green-large-bold {
    display: inline;
    font-size: 1.25em;
    font-weight: 1000;
    color: #00B3B5;
}

.middle-status-element {
    margin-left: 18px; 
    margin-right: 18px;
}

.status-subtext {
    float: right; 
    margin-top: 8px; 
    line-height: 16px;
    padding-right: 12px;
}

.account-details {
    position: fixed;
    display: flex;
    margin-left: 16px;
    margin-right: 16px;
    padding: 12px 0px;
    right: 1%;
    left: 30%;
    top: 22%;
    bottom: 45%;
    width: 67%;
    height: fit-content;
    border-top: solid thin #00B3B5;
}

.change-password-button {
    border: none;
    color: #00B3B5;
    cursor: not-allowed; /* at present */
    font-size: 0.75em;
    font-weight: bold;
    text-decoration: underline;
    margin-right: 8px;
}

.cancel-subscription-container {
    position: fixed;
    bottom: 15%;
    right: 0%;
    margin-right: 16px;
    padding: 12px 12px;
}

.cancel-subscription-button {
    font-size: 0.67em;
    text-align: center;
    color: white;
    background-color: #CCCCCC;
    border: none;
    cursor: not-allowed; /* at present */
    width: 120px;
    height: 20px;
    float: right;
    padding: 0 ;
    margin: 0 auto;
}

strong {
    padding: 0px 0px;
}

p {
    padding: 0px 4px;
}
