/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .create-new-claim-container {
        position: fixed;
        top: 15%;
        bottom: 2%;
        width: inherit;
        overflow-y: auto;
        margin-right: 12px;
        margin-left: 12px;
        padding-right: 16px;
        padding-left: 16px;
        background-color: white;
        border-radius: 24px;
    }

    .form-layout {
        position: sticky;
        display: grid;
        width: 180%;
        box-sizing: border-box;
        grid-template-columns: 50% 50%;
        padding-top: 8px;
        margin-top: 36px;
        margin-left: 12px;
        margin-right: 12px;
    }

    .form-element-style {
        display: grid;
        padding-right: 12px;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .form-element-style-unique {
        display: grid;
        grid-template-columns: 40% 40%;
        padding-right: 12px;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .create-new-claim-label {
        padding: 6px 0;
        font-style: italic;
        font-weight: 500;
        color: #00B3B5;
        text-align: left;
        font-size: 0.85em;
        float: left;
    }

    .create-new-claim-input {
        border: none;
        height: 20px;
        background-color: #e2e2e2;
        float: right;
    }

    .create-new-claim-input-small {
        border: none;
        height: 20px;
        background-color: #e2e2e2;
        float: right;
    }

    .button-update-claim {
        font-size: 0.67em;
        text-align: center;
        justify-self: start;
        color: white;
        background-color: #00B3B5;
        border: none;
        width: 50%;
        height: 20px;
    }

    .button-archive-footer {
        font-size: 0.67em;
        text-align: center;
        color: white;
        justify-self: end;
        background-color: #CCCCCC;
        border: none;
        width: 50%;
        height: 20px;
    }

    .update-claim-container {
        position: fixed;
        display: grid;
        grid-template-columns: 50% 50%;
        box-sizing: border-box;
        top: 90%;
        width: 90%;
        padding-top: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    
    .create-new-claim-container {
        position: fixed;
        top: 15%;
        bottom: 2%;
        width: 100%;
        overflow-y: auto;
        margin-right: 12px;
        margin-left: 12px;
        padding-right: 16px;
        padding-left: 16px;
        background-color: white;
        border-radius: 24px;
        border: none;
    }

    .form-layout {
        position: sticky;
        display: grid;
        width: 180%;
        box-sizing: border-box;
        grid-template-columns: 50% 50%;
        padding-top: 8px;
        margin-top: 36px;
        margin-left: 12px;
        margin-right: 12px;
    }

    .form-element-style {
        display: grid;
        padding-right: 12px;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .form-element-style-unique {
        display: grid;
        grid-template-columns: 40% 40%;
        padding-right: 12px;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .create-new-claim-label {
        padding: 6px 0px;
        font-style: italic;
        font-weight: 500;
        color: #00B3B5;
        text-align: left;
        font-size: 0.85em;
        float: left;
        white-space: nowrap;
    }

    .create-new-claim-input {
        border: none;
        height: 20px;
        background-color: #e2e2e2;
        float: right;
    }

    .create-new-claim-input-small {
        border: none;
        height: 20px;
        background-color: #e2e2e2;
        float: right;
    }

    .button-update-claim {
        font-size: 0.67em;
        justify-self: start;
        text-align: center;
        color: white;
        background-color: #00B3B5;
        border: none;
        width: 40%;
        height: 20px;
        float: left;
    }

    .button-archive-footer {
        font-size: 0.67em;
        text-align: center;
        color: white;
        justify-self: end;
        background-color: #CCCCCC;
        border: none;
        width: 40%;
        height: 20px;
        float: right;
    }

    .update-claim-container {
        position: fixed;
        display: grid;
        grid-template-columns: 50% 50%;
        box-sizing: border-box;
        top: 90%;
        width: 90%;
        padding-top: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .create-new-claim-container {
        position: fixed;
        top: 15%;
        bottom: 2%;
        width: 97%;
        box-sizing: border-box;
        margin-right: 12px;
        margin-left: 12px;
        padding-right: 16px;
        padding-left: 16px;
        background-color: white;
        border-radius: 24px;
        border: none;
        overflow-y: auto;
    }

    .form-layout {
        position: sticky;
        display: grid;
        width: 98%;
        bottom: 49%;
        grid-template-columns: 50% 50%;
        box-sizing: border-box;
        margin-top: 36px;
        padding-top: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

    .form-element-style {
        display: grid;
        padding-right: 12px;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .form-element-style-unique {
        display: grid;
        grid-template-columns: 40% 40%;
        padding-right: 12px;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .create-new-claim-label {
        padding: 6px 0px;
        font-style: italic;
        font-weight: 500;
        color: #00B3B5;
        text-align: left;
        font-size: 0.850em;
        float: left;
    }

    .create-new-claim-input {
        border: none;
        height: 18px;
        background-color: #e2e2e2;
        float: right;
    }

    .create-new-claim-input-small {
        border: none;
        height: 18px;
        background-color: #e2e2e2;
        float: right;
    }

    .button-update-claim {
        font-size: 0.67em;
        text-align: center;
        justify-self: start;
        color: white;
        background-color: #00B3B5;
        border: none;
        width: 30%;
        height: 20px;
    }

    .button-archive-footer {
        font-size: 0.67em;
        text-align: center;
        color: white;
        justify-self: end;
        background-color: #CCCCCC;
        border: none;
        width: 30%;
        height: 20px;
    }

    .update-claim-container {
        position: fixed;
        display: grid;
        grid-template-columns: 50% 50%;
        box-sizing: border-box;
        top: 90%;
        width: 90%;
        padding-top: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    
    .create-new-claim-container {
        position: fixed;
        top: 15%;
        bottom: 2%;
        width: 97%;
        box-sizing: border-box;
        margin-right: 12px;
        margin-left: 12px;
        padding-right: 16px;
        padding-left: 16px;
        background-color: white;
        border-radius: 24px;
        border: none;
        overflow-y: auto;
    }

    .form-layout {
        position: sticky;
        display: grid;
        width: 98%;
        bottom: 49%;
        grid-template-columns: 50% 50%;
        box-sizing: border-box;
        margin-top: 36px;
        padding-top: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

    .form-element-style {
        display: grid;
        padding-right: 12px;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .form-element-style-unique {
        display: grid;
        grid-template-columns: 40% 40%;
        padding-right: 12px;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .create-new-claim-label {
        padding: 6px 0px;
        font-style: italic;
        font-weight: 500;
        color: #00B3B5;
        text-align: left;
        font-size: 0.850em;
        float: left;
    }

    .create-new-claim-input {
        border: none;
        height: 22px;
        background-color: #e2e2e2;
        float: right;
    }

    .create-new-claim-input-small {
        border: none;
        height: 22px;
        background-color: #e2e2e2;
        float: right;
    }

    .button-update-claim {
        font-size: 0.67em;
        justify-self: start;
        text-align: center;
        color: white;
        background-color: #00B3B5;
        border: none;
        width: 30%;
        height: 20px;
    }

    .button-archive-footer {
        font-size: 0.67em;
        text-align: center;
        color: white;
        justify-self: end;
        background-color: #CCCCCC;
        border: none;
        width: 30%;
        height: 20px;
    }

    .update-claim-container {
        position: fixed;
        display: grid;
        grid-template-columns: 50% 50%;
        top: 90%;
        width: 93%;
        box-sizing: border-box;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .create-new-claim-container {
        position: fixed;
        top: 15%;
        bottom: 2%;
        width: 83%;
        box-sizing: border-box;
        margin-right: 12px;
        margin-left: 12px;
        padding-right: 16px;
        padding-left: 16px;
        background-color: white;
        border-radius: 24px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .form-layout {
        position: sticky;
        display: grid;
        margin-top: 24px;
        width: 97%;
        bottom: 49%;
        grid-template-columns: 50% 50%;
        box-sizing: border-box;
        padding: 18px 0px;
        border-bottom: 1px solid #00B3B5;
    }

    .form-element-style {
        display: grid;
        padding-right: 12px;
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .form-element-style-unique {
        display: grid;
        grid-template-columns: 40% 40%;
        padding-right: 12px;
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .create-new-claim-label {
        padding: 6px 0;
        font-style: italic;
        font-weight: 500;
        color: #00B3B5;
        text-align: left;
        font-size: 0.90em;
        float: left;
    }

    .create-new-claim-input {
        border: none;
        height: 20px;
        background-color: #e2e2e2;
        float: right;
        padding: 8px;
    }

    .create-new-claim-input-small {
        border: none;
        height: 20px;
        background-color: #e2e2e2;
        width: auto;
        float: right;
        padding: 8px;
    }

    .create-new-claim-input-small-select {
        border: none;
        height: 100%;
        width: 80%;
        background-color: #e2e2e2;
        float: right;
        padding: 9px;
    }

    .button-update-claim {
        font-size: 0.67em;
        text-align: center;
        justify-self: start;
        color: white;
        background-color: #00B3B5;
        border: none;
        width: 30%;
        height: 20px;
    }

    .button-archive-footer {
        font-size: 0.67em;
        text-align: center;
        color: white;
        justify-self: end;
        margin-right: 12px;
        background-color: #CCCCCC;
        border: none;
        width: 30%;
        height: 20px;
    }

    .update-claim-container {
        display: grid;
        grid-template-columns: 50% 50%;
        position: fixed;
        box-sizing: border-box;
        top: 91%;
        width: 80%;
        padding: 6px 12px;
    }

}

.no-display {
    display: none;
}

.adjustments-container {
    position: fixed;
    top: 54%;
    bottom: 7%;
    width: 80%;
    box-sizing: border-box;
    border-top: solid thin #00B3B5;
    border-bottom: solid thin #00B3B5;
}

.h1-create-new-claim {
    position: absolute;
    display: block;
    float: left;
    padding: 8px 8px;
    font-size: 1.5em;
    color: #526666;
}
.h2-upload-comparison {
    display: block;
    float: left;
    padding: 6px 16px;
    font-size: 1.0em;
    color: #526666;
}

.button-file-upload {
    margin: 6px 6px;
    border-radius: 8px;
    font-size: 0.67em;
    text-align: center;
    color: white;
    background-color: #526666;
    border: none;
    width: 150px;
    height: 20px;
}

.file-upload {
    box-sizing: border-box;
    margin: 0 12px;
    display: flex;
    justify-content: space-between;
    width: 97%;
    height: 30%;
}

.buttons-container {
    margin: 0px 12px 12px 12px;
    padding: 16px 0px 64px 0px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 50% 50%;
    width: 97%;
    border-bottom: 1px solid #00B3B5;
}

.button-submit {
    margin: 6px 6px;
    font-size: 1.0em;
    text-align: center;
    color: white;
    background-color: #526666;
    font-weight: bold;
    border: none;
    width: 200px;
    height: 40px;
    border-radius: 2px;
}

.button-submit-clear {
    margin: 6px 6px;
    font-size: 1.0em;
    text-align: center;
    color: white;
    font-weight: bold;
    background-color: #526666;
    border: none;
    width: 200px;
    height: 40px;
    opacity: 50%;
    pointer-events: none;
    border-radius: 2px;
}

.button-submit-comparison {
    position: absolute !important;
    margin: 92px 6px 0px 6px !important;
    margin: 6px 6px;
    font-size: 1.0em;
    text-align: center;
    font-weight: bold;
    color: white;
    background-color: #526666;
    border: none;
    width: 200px;
    height: 40px;
    border-radius: 2px;
}

.button-submit-comparison-disabled {
    position: absolute;
    margin: 92px 6px 0px 6px;
    font-size: 1.0em;
    text-align: center;
    font-weight: bold;
    color: white;
    background-color: #526666;
    pointer-events: none;
    border: none;
    width: 200px;
    height: 40px;
    opacity: 50%;
    border-radius: 2px;
}

.submit-comparison-container {
    width: 200%;
    display: flex;
    justify-content: flex-end;

}

.uploaded-files-list {
    display: flex;
    justify-content: space-between;
}

.uploaded-files-label {
    color: #00B3B5;
    text-decoration: underline;
    font-weight: bold;
}

.uploaded-files-name {
    color: #333333;
    font-weight: bold;
}

.upload-new-file-button {
    color: #00B3B5;
    font-style: italic;
    font-weight: bold;
    background: none;
    border: none;
}

.upload-new-file-button:hover {
    opacity: 50%;
}

.button-upload {
    position: relative;
    margin: 6px 6px;
    font-size: 0.67em;
    text-align: center;
    color: white;
    background-color: #E2E2E2;
    border: none;
    padding: auto auto;
    width: 150px;
    padding-top: 2px;
    height: 30px;
}

.button-upload:hover {
    opacity: 80%;
}

.button-add-headers {
    margin: 6px 6px;
    font-size: 1.0em;
    font-weight: bold;
    text-align: center;
    color: white;
    background-color: #00B3B5;
    border: none;
    padding: auto auto;
    width: 200px;
    height: 40px;
    border-radius: 2px;
}

.button-upload-disabled {
    position: relative;
    margin: 6px 6px;
    font-size: 1.0em;
    font-weight: bold;
    text-align: center;
    color: white;
    background-color: #E2E2E2;
    border: none;
    padding: auto auto;
    width: 200px;
    height: 40px;
    padding-top: 2px;
    border-radius: 2px;
}

.create-new-container {
    display: flex;
    justify-content: flex-end;
    right: 2%;
    padding: 8px 8px;
    position: fixed;
    top: 15%;
    width: 80%;
    margin: 12px 0;
    padding: 0 48px 18px 0px;
}

.create-new-comparison-button {
    display: block;
    margin: 6px 6px;
    text-align: center;
    color: white;
    background-color: #00B3B5;
    border: none;
    padding: 4px 12px 4px 12px;
    font-size: 20px;
    width: auto;
}

.delete-most-recent-button {
    display: block;
    margin: 6px 6px;
    border-radius: 8px;
    text-align: center;
    color: white;
    background-color: #526666;
    border: none;
    padding: auto auto;
    width: 300px;
    padding-top: 2px;
    height: 30px;
}

.go-to-files-button {
    display: block;
    margin: 6px 6px;
    border-radius: 8px;
    text-align: center;
    color: white;
    background-color: #526666;
    border: none;
    padding: auto auto;
    width: 150px;
    padding-top: 2px;
    height: 30px;
}

.navlink-style-create-new {
    display: block;
    font-size: 0.8em;
    padding-top: 8px;
    padding-bottom: 8px;
    color: white;
    text-decoration: none;
    height: 100%;
    width: 100%;
}

.navlink-style-start-here-p {
    display: inline;
    font-size: 0.8em;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #526666;
    height: 100%;
    width: 100%;
    text-decoration: none;
    font-size: 1.5em;
    text-align: center;
}

.navlink-style-start-here {
    display: inline;
    font-size: 0.8em;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #526666;
    height: 100%;
    width: 100%;
    font-style: italic;
    font-size: 1.25em;
    text-align: center;
}

.navlink-style-start-here:hover {
    opacity: 50%;
}

.on-load-message-container {
    padding: 12px 6px;
    position: fixed;
    display: flex;
    justify-content: center;
    top: 30%;
    align-items: center;
    width: inherit;
}

.navlink-style-submit-comparison {
    display: block;
    font-size: 1.0em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;;
    padding-bottom: 8px;
    padding-top: 2px;
    color: white;
    text-decoration: none;
    height: 100%;
    width: 100%;
}

.view-results-button {
    background-color: #00B3B5;
    box-sizing: border-box;
    white-space: nowrap;
    display: block;
    margin: 6px 6px;
    text-align: center;
    color: white;
    border: none;
    padding: 4px 12px 4px 12px;
    font-size: 0.8em;
    width: 100px;
}

.delete-comparison-button {
    background-color: #555555;
    box-sizing: border-box;
    white-space: nowrap;
    display: block;
    margin: 6px 6px;
    text-align: center;
    color: white;
    border: none;
    padding: 4px 12px 4px 12px;
    font-size: 0.8em;
    width: 70px;
}

.comparison-pending-button {
    color: #526666 !important;
    background-color: #f2f2f2 !important;
    box-sizing: border-box;
    white-space: nowrap;
    display: block;
    margin: 6px 6px;
    text-align: center;
    color: white;
    border: none;
    padding: 4px 12px 4px 12px;
    font-size: 0.8em;
    width: 100px;
}
.comparison-continue-button {
    background-color: #007A7F;
    box-sizing: border-box;
    white-space: nowrap;
    display: block;
    margin: 6px 6px;
    text-align: center;
    color: white;
    border: none;
    padding: 4px 12px 4px 12px;
    font-size: 0.8em;
    width: 100px;
}

.comparison-failed-button {
    box-sizing: border-box;
    white-space: nowrap;
    display: block;
    margin: 6px 6px;
    text-align: center;
    color: white;
    border: none;
    padding: 4px 12px 4px 12px;
    font-size: 0.8em;
    width: 100px;
    background-color: #555555;
}

.view-results-button-nav {
    display: block;
    text-decoration: none;
    height: 100%;
    width: 100%;
}

.dark-grey {
    color: #526666;
}

.white {
    color: white;
}

.all-comparisons-table {
    overflow-y: auto;
    position: fixed;
    margin: 0 auto;
    top: 22%;
    width: 80%;
    height: 70%;
    border-bottom: 1px solid #00B3B5;
    padding: 0 0 18px 0;
}

.all-comparisons-table td {
    padding: 0px 0px;
    font-style: italic;
    font-size: 1.0em;
    color: #526666;
}

.all-comparisons-table th {
    padding: 0px 0px;
    color: #00B3B5;
    font-size: 1.25em;
}

.loading-comparisons-text {
    font-weight: bold;
    color: #1976D2;
    font-size: 1.5em;
}


.columns {
    margin: 0px 4px 0px 16px;
    padding: 0px 6px 0 6px;
    overflow: hidden;
    min-width: 50%;
}

.columns-div-confirm {
    display: flex;
    margin-bottom: 60px;
    padding-bottom: 24px;
    justify-content: flex-start;
    overflow: auto;
    border-bottom: 1px solid #00B3B5
}

.unique-column:first-child {
    border-left: none;
    margin-left: 8px;
}

.unique-column:last-child {
    border-right: none;
    margin-right: 8px;
}

.unique-column {
    display: grid;
    font-size: 16px;
    height: 90%;
    grid-template-rows: 10% 10% 10% 20% 20%;
    margin: 0px 0px 4px 0px;
    padding: 0px 6px 0px 6px;
    border-left: 1px solid #00B3B5;
    border-right: 1px solid #00B3B5;
}

.confirm-column {
    display: grid;
    height: auto;
    grid-template-rows: auto auto auto;
    margin: 0px 0px 4px 0px;
    padding: 0px 36px;
}
.confirm-column:first-child {
    padding-left: 0px;
}

input:hover {
    opacity: 80%;
}

.no-display {
    display: none;
}