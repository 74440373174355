* {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}
a:hover {
  opacity: 50%;
  cursor: pointer;
}
html {
  height: 100%;
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .left-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 80%;
    background-image: url("../img/LEVLR-Login-Background.jpg");
    background-size:  auto;
  }

  .right-container {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    background-color: white;
  }

  .hamburger {
    position: fixed;
    top: 0;
    left: 0;
    margin: 20px 16px 4px 16px;
    display: inline-block;
    cursor: pointer;
    z-index: 62;
  }
  
  .bar1, .bar2, .bar3 {
    width: 24px;
    height: 3px;
    background-color: #000000;
    margin: 4px 0;
    transition: 0.4s;
  }
  
  .hamburger-change {
      position: fixed;
      top: 0;
      left: 68%;
      margin: 8px 16px 4px 16px;
      display: inline-block;
      cursor: pointer;
      z-index: 62;
  }
  
  .hamburger-change .bar1 {
      position: absolute;
      width: 24px;
      height: 3px;
      background-color: white;
      margin: 14px 0;
      transition: 0.4s;
      transform: rotate(45deg);
  }
  
  .hamburger-change .bar2 {
    opacity: 0%;
  }
  
  .hamburger-change .bar3 {
    position: absolute;
    width: 24px;
    height: 3px;
    background-color: white;
    transition: 0.4s;
    transform: rotate(-45deg);
  }

  .empty-div {
    height: 5%;
    width: 50px;
  }
  
  .header-container {
    position: fixed;
    display: flex;
    top: 0;
    right: 0;
    width: 100%;
    height: auto;
    padding-top: 4px;
    justify-content: space-between; 
  }

  .elkmont-logo-resize {
    height: auto;
    width: 35%;
  }
  
  .strong-style {
    font-size: 70%;
    white-space: nowrap;
    color: #526666;
  }
  
  .p-style {
    font-size: 70%;
    white-space: nowrap;
    color: #526666;
  }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

  .left-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 60%;
    background-image: url("../img/LEVLR-Login-Background.jpg");
    background-size:  auto;
  }

  .right-container {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    background-color: white;
  }

  .hamburger {
    position: fixed;
    top: 0;
    left: 0;
    margin: 20px 16px 4px 16px;
    display: inline-block;
    cursor: pointer;
    z-index: 62;
  }
  
  .bar1, .bar2, .bar3 {
    width: 24px;
    height: 3px;
    background-color: #000000;
    margin: 4px 0;
    transition: 0.4s;
  }
  
  .hamburger-change {
      position: fixed;
      top: 0;
      left: 50%;
      margin: 8px 16px 4px 16px;
      display: inline-block;
      cursor: pointer;
      z-index: 62;
  }
  
  .hamburger-change .bar1 {
      position: absolute;
      width: 24px;
      height: 3px;
      background-color: white;
      margin: 14px 0;
      transition: 0.4s;
      transform: rotate(45deg);
  }
  
  .hamburger-change .bar2 {
    opacity: 0%;
  }
  
  .hamburger-change .bar3 {
    position: absolute;
    width: 24px;
    height: 3px;
    background-color: white;
    transition: 0.4s;
    transform: rotate(-45deg);
  }

  .header-container {
    position: fixed;
    display: flex;
    top: 0;
    right: 0;
    width: 100%;
    height: auto;
    padding-top: 4px;
    justify-content: space-between;
  }
  
  .empty-div {
    height: 5%;
    width: 60px;
  }

  .elkmont-logo-resize {
    height: auto;
    width: 35%;
  }
  
  .strong-style {
    font-size: 80%;
    white-space: nowrap;
    color: #526666;
  }
  
  .p-style {
    font-size: 80%;
    white-space: nowrap;
    color: #526666;
  }
  
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

  .left-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background-image: url("../img/LEVLR-Login-Background.jpg");
    background-size:  auto;
  }

  .right-container {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    background-color: white;
  }
  
  .hamburger {
    position: fixed;
    top: 0;
    left: 0;
    margin: 20px 16px 4px 16px;
    display: inline-block;
    cursor: pointer;
    z-index: 62;
  }
  
  .bar1, .bar2, .bar3 {
    width: 24px;
    height: 3px;
    background-color: #000000;
    margin: 4px 0;
    transition: 0.4s;
  }
  
  .hamburger-change {
      position: fixed;
      top: 0;
      left: 45%;
      margin: 8px 16px 4px 16px;
      display: inline-block;
      cursor: pointer;
      z-index: 62;
  }
  
  .hamburger-change .bar1 {
      position: absolute;
      width: 24px;
      height: 3px;
      background-color: white;
      margin: 14px 0;
      transition: 0.4s;
      transform: rotate(45deg);
  }
  
  .hamburger-change .bar2 {
    opacity: 0%;
  }
  
  .hamburger-change .bar3 {
    position: absolute;
    width: 24px;
    height: 3px;
    background-color: white;
    transition: 0.4s;
    transform: rotate(-45deg);
  }

  .header-container {
    position: fixed;
    display: flex;
    top: 0;
    right: 0;
    width: 100%;
    height: auto;
    padding-top: 4px;
    justify-content: space-between;
  }

  .empty-div {
    height: 5%;
    width: 60px;
  }

  .elkmont-logo-resize {
    height: auto;
    width: 40%;
  }
  
  .strong-style {
    font-size: 90%;
    white-space: nowrap;
    color: #526666;
  }
  
  .p-style {
    font-size: 90%;
    white-space: nowrap;
    color: #526666;
  }
  
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

  .left-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 25%;
    background-image: url("../img/LEVLR-Login-Background.jpg");
    background-size:  auto;
  }

  .right-container {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    background-color: white;
  }

  .hamburger {
    position: fixed;
    top: 0;
    left: 0;
    margin: 20px 16px 4px 16px;
    display: inline-block;
    cursor: pointer;
    z-index: 62;
  }
  
  .bar1, .bar2, .bar3 {
    width: 24px;
    height: 3px;
    background-color: #000000;
    margin: 4px 0;
    transition: 0.4s;
  }
  
  .hamburger-change {
      position: fixed;
      top: 0;
      left: 20%;
      margin: 8px 16px 4px 16px;
      display: inline-block;
      cursor: pointer;
      z-index: 62;
  }
  
  .hamburger-change .bar1 {
      position: absolute;
      width: 24px;
      height: 3px;
      background-color: white;
      margin: 14px 0;
      transition: 0.4s;
      transform: rotate(45deg);
  }
  
  .hamburger-change .bar2 {
    opacity: 0%;
  }
  
  .hamburger-change .bar3 {
    position: absolute;
    width: 24px;
    height: 3px;
    background-color: white;
    transition: 0.4s;
    transform: rotate(-45deg);
  }

  .header-container {
    position: fixed;
    display: flex;
    top: 0;
    right: 0;
    width: 100%;
    height: auto;
    padding-top: 4px;
    justify-content: space-between;
  }
  
  .empty-div {
    height: 5%;
    width: 60px;
  }

  .elkmont-logo-resize {
    height: auto;
    width: 50%;
  }
  
  .strong-style {
    font-size: 0.75em;
    color: #526666;
  }
  
  .p-style {
    font-size: 0.67em;
    color: #526666;
  }
  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

  .left-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 15%;
  }

  .right-container {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 85%;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    background-color: white;
  }

  .hamburger {
    display: none;
  }
  
  .hamburger-change {
    display: none;
  }

  .header-container {
    position: fixed;
    display: flex;
    top: 0;
    right: 0;
    width: 83%;
    height: auto;
    padding: 16px;
    margin: 0 12px;
    box-sizing: border-box;
    justify-content: space-evenly;
  }

  .empty-div {
    display: none;
  }

  .elkmont-logo-resize {
    /*filter: grayscale(1) brightness(0) invert(1);*/
    height: auto;
    width: 90%;
  }
  
  .strong-style {
    font-size: 0.75em;
    color: #526666;
  }
  
  .p-style {
    font-size: 0.67em;
    color: #526666;
  }

}

#root {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.outer-container {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url("../img/LEVLR-Login-Background.jpg");
  background-size:  auto;
  z-index: 10;
}

.left-container {
  z-index: 60;
}

.content-container {
  z-index: 50;
}

.right-container {
  background-color: #DDDEDD;
  z-index: 20;
}

.elkmont-logo-container {
  position: fixed;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  top: 0;
  bottom: 85%;
  width: inherit;
  padding: 6px 6px;
  z-index: 61;
}

.profile-picture-container {
  padding: 8px 0;
}

.profile-info-container {
  padding: 8px 0;
  margin-bottom: 8px;
}

.user-container {
  margin-left: 12px;
  text-decoration: none;
  display: grid;
  grid-template-columns: 20% 80%;
  vertical-align: middle;
  align-items: center;
  justify-self: center;
}

.content-container {
  float: right;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #DDDEDD;
}

.nav-list {
  position: fixed;
  top: 15%;
  width: inherit;
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-content {
  position: fixed;
  height: 100%;
  width: 100%;
}

nav ul {
  width: 100%;
  padding-left: 0px;
  margin: 0px 0px 0px 0px;
}

nav ul li {
  position: relative;
  display: list-item;
  line-height: 46px;
  margin-left: 16px;
  list-style: none;
}

nav ul li a {
  display: block;
  font-weight: bold;
  font-size: 0.75em;
  color: white;
  text-decoration: none;
  text-align: left;	
  width: inherit;
  padding-left: 25%;
  padding-right: 5%;
}

/* Inverted border for nav */
nav ul li a:hover::before {
  content: "";
  position: absolute;
  height: 46px;
  width: 20px;
  right: 0;
  bottom: -46px;
  border-top-right-radius: 24px;
  box-shadow: 0 -23px 0 0 #DDDEDD;
}

nav ul li a:hover::after {
  content: "";
  position: absolute;
  background: none;
  height: 46px;
  width: 20px;
  right: 0;
  top: -46px;
  border-bottom-right-radius: 24px;
  box-shadow: 0 23px 0 0 #DDDEDD;
}

.clicked-dashboard::before, .clicked-active-claims::before, .clicked-archived-claims::before, .clicked-insurance-companies::before, .clicked-statistics::before, .clicked-reports::before, .clicked-estimates::before, .clicked-claims::before {  content: "";
  position: absolute;
  height: 46px;
  width: 20px;
  right: 0;
  bottom: -46px;
  border-top-right-radius: 24px;
  box-shadow: 0 -23px 0 0 #DDDEDD;
}

.clicked-dashboard::after, .clicked-active-claims::after, .clicked-archived-claims::after, .clicked-insurance-companies::after, .clicked-statistics::after, .clicked-reports::after, .clicked-estimates::after, .clicked-claims::after {  content: "";
  content: "";
  position: absolute;
  background: none;
  height: 46px;
  width: 20px;
  right: 0;
  top: -46px;
  border-bottom-right-radius: 24px;
  box-shadow: 0 23px 0 0 #DDDEDD;
}
nav ul li:nth-child(1) {
  background: url("../img/dashboard-icon-white.png") 4% 50% no-repeat;
}

nav ul li:nth-child(1):hover {
  background: url("../img/dashboard-icon-green.png") 4% 50% no-repeat;
  background-color: #DDDEDD;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.clicked-dashboard { 
  background: url("../img/dashboard-icon-green.png") 4% 50% no-repeat;
  background-color: #DDDEDD;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

nav ul li:nth-child(2) {
  background: url("../img/open-claims-icon-white.png") 4% 50% no-repeat;
}

nav ul li:nth-child(2):hover {
  background: url("../img/open-claims-icon-teal.png") 4% 50% no-repeat;
  background-color: #DDDEDD;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.clicked-active-claims {
  background: url("../img/open-claims-icon-teal.png") 4% 50% no-repeat;
  background-color: #DDDEDD;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

nav ul li:nth-child(3) {
  background: url("../img/archived-claims-icon-white.png") 4% 50% no-repeat;
}

nav ul li:nth-child(3):hover {
  background: url("../img/archived-claims-teal.png") 4% 50% no-repeat;
  background-color: #DDDEDD;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.clicked-archived-claims {
  background: url("../img/archived-claims-teal.png") 4% 50% no-repeat;
  background-color: #DDDEDD;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

nav ul li:nth-child(4) {
  background: url("../img/insurance-companies-icon-white.png") 4% 50% no-repeat;
}

nav ul li:nth-child(4):hover {
  background: url("../img/insurance-companies-icon-teal.png") 4% 50% no-repeat;
  background-color: #DDDEDD;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.clicked-insurance-companies {
  background: url("../img/insurance-companies-icon-teal.png") 4% 50% no-repeat;
  background-color: #DDDEDD;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

nav ul li:nth-child(5) {
  background: url("../img/statistics-icon-white.png") 4% 50% no-repeat;
}

nav ul li:nth-child(5):hover {
  background: url("../img/statistics-icon-teal.png") 4% 50% no-repeat;
  background-color: #DDDEDD;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.clicked-statistics {
  background: url("../img/statistics-icon-teal.png") 4% 50% no-repeat;
  background-color: #DDDEDD;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

nav ul li:nth-child(6) {
  background: url("../img/reports-icon-white.png") 4% 50% no-repeat;
}

nav ul li:nth-child(6):hover {
  background: url("../img/reports-icon-teal.png") 4% 50% no-repeat;
  background-color: #DDDEDD;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.clicked-statistics {
  background: url("../img/reports-icon-teal.png") 4% 50% no-repeat;
  background-color: #DDDEDD;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

nav ul li:nth-child(7) {
  background: url("../img/reports-icon-white.png") 4% 50% no-repeat;
}

nav ul li:nth-child(7):hover {
  background: url("../img/reports-icon-teal.png") 4% 50% no-repeat;
  background-color: #DDDEDD;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.clicked-reports {
  background: url("../img/reports-icon-teal.png") 4% 50% no-repeat;
  background-color: #DDDEDD;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

nav ul li:nth-child(8) {
  background: url("../img/reports-icon-white.png") 4% 50% no-repeat;
}

nav ul li:nth-child(8):hover {
  background: url("../img/reports-icon-teal.png") 4% 50% no-repeat;
  background-color: #DDDEDD;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.clicked-estimates {
  background: url("../img/reports-icon-teal.png") 4% 50% no-repeat;
  background-color: #DDDEDD;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

nav ul li:nth-child(9) {
  background: url("../img/reports-icon-white.png") 4% 50% no-repeat;
}

nav ul li:nth-child(9):hover {
  background: url("../img/reports-icon-teal.png") 4% 50% no-repeat;
  background-color: #DDDEDD;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.clicked-claims {
  background: url("../img/reports-icon-teal.png") 4% 50% no-repeat;
  background-color: #DDDEDD;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.clicked-dashboard, .clicked-active-claims, .clicked-archived-claims, .clicked-insurance-companies, .clicked-statistics, .clicked-reports, .clicked-estimates, .clicked-claims {
  color: #399BA5;
}

nav ul li a:hover {
  color: #399BA5;
}

.levlr-logo-resize {
  width: 50%;
  height: auto;
}

.hamburger:hover, .hamburger-change:hover, .button-page:hover, .user-container:hover, button:hover, .x-container:hover {
  opacity: 50%;
}

.header-start { 
  display: flex;
  width: 40%; 
  justify-content: flex-start;
}

.header-middle {
  margin-top: 8px;
  display: flex;
  width: 20%;
  justify-content: center;
}

.header-end {
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: flex-end;
}

.button-chat {
  border: none;
  width: 80px;
  height: auto;
  font-size: 0.75em;
  border-radius: 4px;
  background: none;
  cursor: not-allowed; /* at present */
}

.button-notifications {
  border: none;
  width: 80px;
  height: auto;
  font-size: 0.75em;
  border-radius: 4px;
  background: none;
  cursor: not-allowed; /* at present */
}

*::-webkit-scrollbar {
  width: 12px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  margin: 16px 16px;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  z-index: 200;
  position: fixed;
  top: 40%;
  left: 54%;
  width: 50px;
  height: 50px;
  border: 10px solid #526666;
  border-top: 10px solid #00B3B5;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.loading-spinner-small {
  z-index: 200;
  position: absolute;
  margin-top: -16px;
  margin-left: -20px;
  width: 8px;
  height: 8px;
  border: 4px solid #526666;
  border-top: 4px solid #00B3B5;
  border-radius: 50%;
  animation: spinner 1.0s linear infinite;
}
.checkmark:after {
  content: '';
  z-index: 200;
  position: absolute;
  margin-top: -16px;
  margin-left: -12px;
  width: 3px;
  height: 8px;
  border: solid #00B3B5;
  border-width: 0 4px 4px 0;
  transform: rotate(45deg);
}

.card {
  background-color: white;
  padding: 10px 20px 11px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #afafaf;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}
.pay-button{
  padding: 0.7rem 2rem;
  width: 108%;
  margin: 1rem 0;
  color: white;
  font-weight: bold;
  font-size: medium;
  background-color: #00B3B5;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  transition: box-shadow 500ms;
  cursor: pointer;
}

.hidden {
  display: none;
  height: 0px;
  width: 0px;
}

.pay-button:disabled{
  background-color: #afafaf;
  box-shadow: none;
  cursor: default;
}
.pay-button:disabled:hover{
  box-shadow: none;
}
.pay-button:hover{
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
}
.green-button {
  width: fit-content;
  white-space: nowrap;
  height: fit-content;
  color: white;
  background-color: #00B3B5;
  border: none;
  font-size: 18px;
  padding: 8px 24px;
}

.gray-button {
  width: fit-content;
  white-space: nowrap;
  height: fit-content;
  color: white;
  background-color: #CCCCCC;
  border: none;
  font-size: 18px;
  padding: 8px 24px;
}

.default-payments {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70px;
  padding: 0 20%;
  margin-bottom: 16px;
}
.default-payments h2 {
  color: #00B3B5;
}
.default-payments select {
  border: 1px solid #00B3B5;
  border-radius: 2px;
  height: 30px;
  font-style: italic;
  font-size: 18px;
  color: #555555;
}

.users-select {
  border: 2px solid #00B3B5;
  border-radius: 2px;
  height: 30px;
  font-style: italic;
  font-size: 18px;
  color: #555555;
}

.card-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90px;
  padding: 0 20%;
  margin-bottom: 16px;
}
.card-element {
  padding: 12px 24px;
  box-sizing: border-box;
  background-color: #DDDDDD;
}

.subscribe-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20%;
  margin-top: 32px;
  height: 80px;
}
.subscribe-form input {
  border: 1px solid #00B3B5;
  border-radius: 2px;
  height: 30px;
  font-style: italic;
  font-size: 18px;
  color: #555555;
  padding: 0px 4px;
}

.price-model {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20%;
  height: 80px;
  margin-top: 16px;
}
.price-model p {
  font-weight: bold;
  margin: 6px 0;
  padding: 0 0;
  font-size: 20px;
  color: #00B3B5;
}
.price-model strong {
  color: #555555;
}

.cancel-container {
  position: fixed;
  display: flex;
  margin-left: 16px;
  margin-right: 16px;
  padding: 12px 0px;
  right: 2%;
  left: 17%;
  top: 86%;
  height: fit-content;
  border-top: 1px solid #00B3B5;
  justify-content: flex-end;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}