/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    
    .admin-container {
        position: fixed;
        top: 15%;
        width: inherit;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background: white;
        border-radius: 24px;
        overflow: auto;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
        
    .admin-container {
        position: fixed;
        top: 15%;
        width: inherit;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background: white;
        border-radius: 24px;
        overflow: auto;
        align-items: center;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
   
    .admin-container {
        position: fixed;
        top: 15%;
        width: inherit;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background: white;
        border-radius: 24px;
        overflow: auto;
    }
}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    
    .admin-container {
        position: fixed;
        top: 15%;
        width: inherit;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background: white;
        border-radius: 24px;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
    .admin-container {
        position: fixed;
        top: 15%;
        width: 83%;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background-color: white;
        border-radius: 24px;
    }
}

.usercontainer1 {
    border-right: dashed #555555 1px;
}

.admin-container h1 {
    position: fixed;
    top: 15%;
    padding: 8px 8px;
    font-size: 1.5em;
    color: #555555;
}

table{
    width: 100%;
    margin-top: 5%;
    height: auto;
    border-collapse: collapse;
}

.activeUsers th {
    width: auto;
    vertical-align: bottom;
}

.activeUsers td {
    width: auto;
}
.activeUsers tr:hover {
    opacity: 50%;
}

.activeUsers tbody {
    overflow: auto;
}
.activeUsers td:nth-child(3), .activeUsers td:nth-child(4), .activeUsers td:nth-child(5), .activeUsers td:nth-child(6), .activeUsers th:nth-child(3), .activeUsers th:nth-child(4), .activeUsers th:nth-child(5), .activeUsers th:nth-child(6) {
    text-align: center;
}