/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .statistics-container {
        white-space: nowrap;
        position: fixed;
        display: grid;
        grid-template-columns: 100% 100% 100% 100%;
        grid-auto-rows: 100%;
        top: 20%;
        bottom: 0%;
        box-sizing: border-box;
        width: 100%;
        padding-top: 8px;
        padding-left: 16px;
        padding-right: 16px;
        background: none;
        border-radius: 4px;
        overflow: hidden;
    }

    .variants-container {
        position: relative;
        background-color: #018391;
        box-sizing: border-box;
        border-radius: 8px;
        padding-top: 8px;
        padding-bottom: 8px; 
        padding-left: 16px;
        padding-right: 16px;
        width: 95%;
        height: 95%;
        overflow: hidden;
    }

    .variants-container-headers {
        position: absolute;
        top: 0%;
        bottom: 90%;
        margin: 12px 12px 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }

    .inline-or-block {
        display: inline;
    }

    .active-estimates-container {
        overflow: hidden;
        position: relative;
        background-color: #8BC741;
        box-sizing: border-box;
        border-radius: 8px; 
        padding-top: 8px;
        padding-bottom: 8px; 
        padding-left: 16px;
        padding-right: 16px;
        width: 95%;
        height: 95%;
    }

    .active-estimates-container-headers {
        position: absolute;
        top: 0%;
        bottom: 90%;
        margin: 12px 12px 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }

    .closed-estimates-container {
        overflow: hidden;
        position: relative;
        background-color: #F4C831;
        box-sizing: border-box;
        border-radius: 8px;
        padding-top: 8px;
        padding-bottom: 8px; 
        padding-left: 16px;
        padding-right: 16px;
        width: 95%;
        height: 95%;
    }

    .closed-estimates-container-headers {
        position: absolute;
        top: 0%;
        bottom: 90%;
        margin: 12px 12px 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }

    .weekly-container {
        overflow: hidden;
        position: relative;
        background-color: #42C7D0;
        box-sizing: border-box;
        border-radius: 8px;
        padding-top: 8px;
        padding-bottom: 8px; 
        padding-left: 16px;
        padding-right: 16px;
        width: 95%;
        height: 95%;
    }
  
    .weekly-container-headers {
        position: absolute;
        top: 0%;
        bottom: 90%;
        margin: 12px 12px 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }

    .header-bold-large {
        font-weight: bold;
        font-size: 1.50em;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

    .statistics-container {
        position: fixed;
        display: grid;
        grid-template-columns: 1;
        grid-auto-rows: 25% 25% 25% 25%;
        top: 20%;
        bottom: 0%;
        box-sizing: border-box;
        width: 100%;
        padding-top: 8px;
        padding-left: 16px;
        padding-right: 16px;
        background: none;
        border-radius: 4px;
    }

    .variants-container {
        position: relative;
        background-color: #018391;
        box-sizing: border-box;
        border-radius: 8px;
        padding-top: 8px;
        padding-bottom: 8px; 
        padding-left: 16px;
        padding-right: 16px;
        height: 95%;
    }

    .variants-container-headers {
        position: absolute;
        top: 0%;
        bottom: 90%;
        margin: 12px 12px 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }

    .inline-or-block {
        display: inline;
    }

    .active-estimates-container {
        position: relative;
        background-color: #8BC741;
        box-sizing: border-box;
        border-radius: 8px; 
        padding-top: 8px;
        padding-bottom: 8px; 
        padding-left: 16px;
        padding-right: 16px;
        height: 95%;
    }

    .active-estimates-container-headers {
        position: absolute;
        top: 0%;
        bottom: 90%;
        margin: 12px 12px 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }

    .closed-estimates-container {
        position: relative;
        background-color: #F4C831;
        box-sizing: border-box;
        border-radius: 8px;
        padding-top: 8px;
        padding-bottom: 8px; 
        padding-left: 16px;
        padding-right: 16px;
        height: 95%;
    }

    .closed-estimates-container-headers {
        position: absolute;
        top: 0%;
        bottom: 90%;
        margin: 12px 12px 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }

    .weekly-container {
        position: relative;
        background-color: #42C7D0;
        box-sizing: border-box;
        border-radius: 8px;
        padding-top: 8px;
        padding-bottom: 8px; 
        padding-left: 16px;
        padding-right: 16px;
        height: 95%;
    }

    .weekly-container-headers {
        position: absolute;
        top: 0%;
        bottom: 90%;
        margin: 12px 12px 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }

    .header-bold-large {
        font-weight: bold;
        font-size: 1.50em;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .statistics-container {
        position: fixed;
        display: grid;
        grid-template-columns: 1;
        grid-auto-rows: 25% 25% 25% 25%;
        top: 20%;
        bottom: 0%;
        box-sizing: border-box;
        width: 100%;
        padding-top: 8px;
        padding-left: 16px;
        padding-right: 16px;
        background: none;
        border-radius: 4px;
    }

    .variants-container {
        position: relative;
        background-color: #018391;
        box-sizing: border-box;
        border-radius: 8px;
        padding-top: 8px;
        padding-bottom: 8px; 
        padding-left: 16px;
        padding-right: 16px;
        height: 95%;
    }
    
    .variants-container-headers {
        position: absolute;
        top: 0%;
        bottom: 90%;
        margin: 12px 12px 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }

    .inline-or-block {
        display: inline;
    }

    .active-estimates-container {
        position: relative;
        background-color: #8BC741;
        box-sizing: border-box;
        border-radius: 8px; 
        padding-top: 8px;
        padding-bottom: 8px; 
        padding-left: 16px;
        padding-right: 16px;
        height: 95%;
    }

    .active-estimates-container-headers {
        position: absolute;
        top: 0%;
        bottom: 90%;
        margin: 12px 12px 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }

    .closed-estimates-container {
        position: relative;
        background-color: #F4C831;
        box-sizing: border-box;
        border-radius: 8px;
        padding-top: 8px;
        padding-bottom: 8px; 
        padding-left: 16px;
        padding-right: 16px;
        height: 95%;
    }

    .closed-estimates-container-headers {
        position: absolute;
        top: 0%;
        bottom: 90%;
        margin: 12px 12px 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }

    .weekly-container {
        position: relative;
        background-color: #42C7D0;
        box-sizing: border-box;
        border-radius: 8px;
        padding-top: 8px;
        padding-bottom: 8px; 
        padding-left: 16px;
        padding-right: 16px;
        height: 95%;
    }

    .weekly-container-headers {
        position: absolute;
        top: 0%;
        bottom: 90%;
        margin: 12px 12px 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }

    .header-bold-large {
        font-weight: bold;
        font-size: 1.50em;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .statistics-container {
        position: fixed;
        display: grid;
        grid-template-columns: 1;
        grid-auto-rows: 25% 25% 25% 25%;
        top: 20%;
        bottom: 0%;
        box-sizing: border-box;
        width: 100%;
        padding-top: 8px;
        padding-left: 16px;
        padding-right: 16px;
        background: none;
        border-radius: 4px;
    }

    .variants-container {
        position: relative;
        background-color: #018391;
        box-sizing: border-box;
        border-radius: 8px;
        padding-top: 8px;
        padding-bottom: 8px; 
        padding-left: 16px;
        padding-right: 16px;
        height: 95%;
    }

    
    .variants-container-headers {
        position: absolute;
        top: 0%;
        bottom: 90%;
        margin: 12px 12px 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }

    .inline-or-block {
        display: inline;
    }

    .active-estimates-container {
        position: relative;
        background-color: #8BC741;
        box-sizing: border-box;
        border-radius: 8px; 
        padding-top: 8px;
        padding-bottom: 8px; 
        padding-left: 16px;
        padding-right: 16px;
        height: 95%;
    }

    .active-estimates-container-headers {
        position: absolute;
        top: 0%;
        bottom: 90%;
        margin: 12px 12px 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }

    .closed-estimates-container {
        position: relative;
        background-color: #F4C831;
        box-sizing: border-box;
        border-radius: 8px;
        padding-top: 8px;
        padding-bottom: 8px; 
        padding-left: 16px;
        padding-right: 16px;
        height: 95%;
    }

    .closed-estimates-container-headers {
        position: absolute;
        top: 0%;
        bottom: 90%;
        margin: 12px 12px 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }

    .weekly-container {
        position: relative;
        background-color: #42C7D0;
        box-sizing: border-box;
        border-radius: 8px;
        padding-top: 8px;
        padding-bottom: 8px; 
        padding-left: 16px;
        padding-right: 16px;
        height: 95%;
    }

    .weekly-container-headers {
        position: absolute;
        top: 0%;
        bottom: 90%;
        margin: 12px 12px 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }

    .header-bold-large {
        font-weight: bold;
        font-size: 1.50em;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .statistics-container {
        position: fixed;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        top: 20%;
        bottom: 5%;
        box-sizing: border-box;
        width: 85%;
        padding-top: 8px;
        padding-left: 16px;
        padding-right: 16px;
        background: none;
        border-radius: 4px;
    }

    .variants-container {
        position: relative;
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
        background-color: #018391;
        box-sizing: border-box;
        border-radius: 8px;
        margin-bottom: 8px;
        height: 100%;
        margin-right: 8px;
    }

    .variants-container-headers {
        position: absolute;
        top: 0%;
        bottom: 90%;
        margin: 12px 20% 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }
    
    .inline-or-block {
        display: grid;
        grid-template-rows: 50% 50%;
    }
    
    .active-estimates-container {
        position: relative;
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
        background-color: #8BC741;
        box-sizing: border-box;
        border-radius: 8px; 
        margin-bottom: 8px; 
        padding-right: 16px;
        height: 100%;
    }

    .active-estimates-container-headers {
        position: absolute;
        white-space: nowrap;
        top: 0%;
        bottom: 90%;
        margin: 12px 20% 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }
    
    .closed-estimates-container {
        position: relative;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 5;
        background-color: #F4C831;
        box-sizing: border-box;
        border-radius: 8px;
        margin-bottom: 8px;
        margin-top: 8px;
        height: 100%;
        margin-right: 8px;
    }

    .closed-estimates-container-headers {
        position: absolute;
        white-space: nowrap;
        top: 0%;
        bottom: 90%;
        margin: 12px 20% 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }

    .weekly-container {
        position: relative;
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 5;
        background-color: #42C7D0;
        box-sizing: border-box;
        border-radius: 8px;
        margin-bottom: 8px;
        margin-top: 8px;
        padding-right: 16px;
        height: 100%;
    }
    
    .weekly-container-headers {
        position: absolute;
        top: 0%;
        bottom: 90%;
        margin: 12px 20% 0px 12px;
        color: white;
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: 40px;
        align-items: center;
    }

    .header-bold-large {
        font-weight: bold;
        font-size: 3.0em;
    }

}

.table-container {
    display: inline-block;
    position: absolute;
    top: 20%;
    bottom: 13%;
    width: 95%;
    overflow: hidden;
}

table {
    border-spacing: 0px 4px;
    border-collapse: separate;
    table-layout: fixed;
    width: 100%;
    padding: 12px 12px;
}

table tbody {
    overflow: hidden;
}

th {
    padding: 0px 12px 0px 12px;
}

td {
    padding: 2px 12px 2px 12px;
    white-space: nowrap;
    overflow: hidden;
}

.h1-statistics {
    position: fixed;
    top: 15%;
    bottom: 80%;
    margin-left: 16px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 1.5em;
    padding-bottom: 16px;
    color: #555555;
}

span, p, strong {
    padding-left: 8px;
    padding-right: 8px;
}

.variants-container th {
    text-align: left;
    color: #50CCD4;
    border-collapse: collapse;
    font-weight: 400;
    border-bottom: solid thin #50CCD4;
}

.variants-container td {
    color: white;
    font-weight: 400;
}

.variants-view-all {
    position: absolute;
    bottom: 1%;
    right: 1%;
    float: right;
    color: #50CCD4;
    background: none;
    border: none;
    font-weight: 700;
    padding-right: 12px;
    padding-bottom: 12px;
    font-size: 0.75em;
}

.active-estimates-container th {
    text-align: left;
    color: #72984D;
    border-collapse: collapse;
    font-weight: 400;
    border-bottom: solid thin #72984D;
}

.active-estimates-container td {
    color: white;
    font-weight: 400;
}

.active-estimates-view-all {
    position: absolute;
    bottom: 1%;
    right: 1%;
    float: right;
    color: #72984D;
    background: none;
    border: none;
    font-weight: 700;
    padding-right: 12px;
    padding-bottom: 12px;
    font-size: 0.75em;
}

.closed-estimates-container th {
    text-align: left;
    color: #C1914F;
    border-collapse: collapse;
    font-weight: 400;
    border-bottom: solid thin #C1914F;
}

.closed-estimates-container td {
    color: white;
    font-weight: 400;
}

.table-summary {
    position: absolute;
    top: 87%;
    bottom: 1%;
    left: 3%;
    border-top: solid thin #C1914F;
    color: white;
    font-weight: bold;
}

.table-summary span {
    font-size: 0.75em;
}

.table-summary strong {
    font-size: 1.0em;
}

.closed-estimates-view-all {
    position: absolute;
    bottom: 1%;
    right: 1%;
    color: #C1914F;
    background: none;
    border: none;
    font-weight: 700;
    padding-right: 12px;
    padding-bottom: 12px;
    font-size: 0.75em;
}

.weekly-container th {
    text-align: left;
    color: #389EAC;
    border-collapse: collapse;
    font-weight: 400;
    border-bottom: solid thin #389EAC;
}

.weekly-container td {
    color: white;
    font-weight: 400;
}

.weekly-view-all {
    position: absolute;
    bottom: 1%;
    right: 1%;
    color: #389EAC;
    background: none;
    border: none;
    font-weight: 700;
    padding-right: 12px;
    padding-bottom: 12px;
    font-size: 0.75em;
}

.header-bold {
    font-weight: bold;
    font-size: 1.50em;
}

.header-light {
    font-weight: 300;
    font-size: 1.25em;
}